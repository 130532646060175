import {
  FILTER_RESPONSE_DATA_REQUEST,
  FILTER_RESPONSE_DATA_SUCCESS,
  FILTER_RESPONSE_DATA_FAILURE,
  RESET_STATE,
} from "../../action/types";

const initialState = {
  data: [],
  loading: false,
  error: null,
};

const FilterResponseDataReducer = (state = initialState, action) => {
  switch (action.type) {
    case FILTER_RESPONSE_DATA_REQUEST:
      return { ...state, loading: true, error: null };
    case FILTER_RESPONSE_DATA_SUCCESS:
      return { ...state, data: action.payload, loading: false, error: null };
    case RESET_STATE:
      return initialState;
    case FILTER_RESPONSE_DATA_FAILURE:
      return { ...state, loading: false, error: action.payload };
    default:
      return state;
  }
};

export default FilterResponseDataReducer;
