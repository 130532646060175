import React from "react";
import RoutesList from "./routing/PublicRouting";
import "gridstack/dist/gridstack.css";
import "./App.css";
import "./i18n";

function App() {
  return <RoutesList />;
}

export default App;
