import Cookies from "js-cookie";
import {
  RECREATE_ACCESS_TOKEN_REQUEST,
  RECREATE_ACCESS_TOKEN_SUCCESS,
  RECREATE_ACCESS_TOKEN_FAILURE,
} from "../types";
import axiosInstance from "../../services/axiosInstance";
import logoutAction from "./LogoutAction";
import resetState from "../common/ResetStateAction";

const recreateAccessTokenAction = () => {
  const clearAllCookies = () => {
    // Get all cookie names
    const cookieNames = Cookies.get();
    // Iterate over each cookie name and remove it
    Object.keys(cookieNames).forEach((cookieName) => {
      Cookies.remove(cookieName);
    });
    // Optionally, update state or perform other actions after clearing all cookies
  };

  return (dispatch) => {
    dispatch({
      type: RECREATE_ACCESS_TOKEN_REQUEST,
    });
    axiosInstance
      .post(`v1/token/refresh/`, {
        refresh: Cookies.get("refresh_token"),
      })
      .then((response) => {
        if (response?.data?.error === "Token is invalid or expired") {
          dispatch(logoutAction());
          setTimeout(() => {
            window.location = "/signin";
          }, 2000);
        } else {
          dispatch({
            type: RECREATE_ACCESS_TOKEN_SUCCESS,
            payload: response?.data,
          });

          Cookies.set("access_token", response?.data?.access);
          setTimeout(() => {
            window.location.reload();
          }, 2000);
        }
      })
      .catch((error) => {
        dispatch({
          type: RECREATE_ACCESS_TOKEN_FAILURE,
          payload: error?.message,
        });
        if (error?.response?.status === 401) {
          clearAllCookies();
          dispatch(resetState());
        }
      });
  };
};

export default recreateAccessTokenAction;
