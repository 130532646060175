import React, { lazy } from "react";
import { Routes, Route } from "react-router-dom";
import PrivateRoute from "./PrivateGuard";

const Home = lazy(() => import("../components/home"));
const Profile = lazy(() => import("../components/profile"));
const Login = lazy(() => import("../components/auth/login"));
const ResetPassword = lazy(() => import("../components/auth/resetPassword"));
const Signup = lazy(() => import("../components/auth/signUp"));
const EmailVerification = lazy(() =>
  import("../components/verification/EmailVerification")
);
const SmartSearch = lazy(() => import("../components/smartSearch"));
const Favorite = lazy(() => import("../components/favourite"));
const Details = lazy(() => import("../components/details"));
const Partners = lazy(() => import("../components/partners"));
const Events = lazy(() => import("../components/events"));
const CsrConference = lazy(() => import("../components/events/CsrConference"));
const SocialMediaAndBranding = lazy(() =>
  import("../components/events/SocialMediaAndBranding")
);
const ContactUs = lazy(() => import("../components/contactus"));
const NotFound = lazy(() => import("../components/commonComponents/404"));

const RoutesList = () => {
  return (
    <Routes>
      <Route path={"/"} element={<PrivateRoute />}>
        {/* Home page */}
        <Route exact path={"/"} element={<Home />} />
        {/* login page */}
        <Route exact path={"/signin"} element={<Login />} />
        {/* reset password */}
        <Route
          exact
          path={"/reset-password/:uid/:token"}
          element={<ResetPassword />}
        />
        {/* signup page */}
        <Route exact path={"/signup"} element={<Signup />} />
        {/* Profile page */}
        <Route path={"/profile"} element={<Profile />} />
        {/* Email verification page */}
        <Route exact path={"/verify/:token"} element={<EmailVerification />} />
        {/* Smart Search page */}
        <Route path={"/directory"} element={<SmartSearch />} />
        <Route path={"/directory/f"} element={<SmartSearch />} />
        {/* Favorite page */}
        <Route path={"/favorite"} element={<Favorite />} />
        {/* NGO Detail Page */}
        <Route path={`/details/:type/:flag/:slug/:id`} element={<Details />} />
        {/* Partners Page */}
        <Route exact path={"/partners"} element={<Partners />} />
        {/* Events Page */}
        <Route exact path={"/events"} element={<Events />} />
        <Route
          exact
          path={"/events/csr-conference-2024"}
          element={<CsrConference />}
        />
        <Route
          exact
          path={
            "/events/social-media-and-branding-strategies-for-ngos-and-csr-organizations"
          }
          element={<SocialMediaAndBranding />}
        />
        {/* Contact page */}
        <Route exact path={"/contact-us"} element={<ContactUs />} />
        {/* not found page */}
        <Route exact path={"*"} element={<NotFound />} />
      </Route>
    </Routes>
  );
};

export default RoutesList;
