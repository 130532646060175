import {
  SUMMARY_DATA_REQUEST,
  SUMMARY_DATA_SUCCESS,
  SUMMARY_DATA_FAILURE,
} from "../../action/types";

const initialState = {
  data: [],
  loading: false,
  error: null,
};

const summaryTabReducer = (state = initialState, action) => {
  switch (action.type) {
    case SUMMARY_DATA_REQUEST:
      return { ...state, loading: true, error: null };
    case SUMMARY_DATA_SUCCESS:
      return {
        ...state,
        data: action.payload,
        loading: false,
        error: null,
      };
    case SUMMARY_DATA_FAILURE:
      return { ...state, loading: false, error: action.payload };
    default:
      return state;
  }
};

export default summaryTabReducer;
