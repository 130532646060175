import React from "react";

export default function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export const languages = [
  {
    code: "en",
    name: "English",
  },
  {
    code: "hi",
    name: "Hindi",
  },
  {
    code: "gu",
    name: "Gujarati",
  },
];

export const entity_type = [
  { id: "select", title: "Select" },
  { id: "people", title: "People" },
  { id: "ngo", title: "NGO" },
  { id: "corporate", title: "Corporate" },
];

export const linksArray = [
  {
    id: "whatIsAkarma",
    text: "What is Akarma",
    className: "drop-item",
    offset: 100,
  },
  {
    id: "ourMission",
    text: "Our Mission",
    className: "drop-item",
    offset: 100,
  },
  {
    id: "ourPhilosophy",
    text: "Our Philosophy",
    className: "drop-item",
    offset: 100,
  },
  {
    id: "initiatives",
    text: "Akarma Initiatives",
    className: "drop-item",
    offset: 100,
  },
  {
    id: "team",
    text: "Evangelists",
    className: "drop-item",
    offset: 100,
  },
];

export const profileArray = [
  {
    id: "profile",
    text: "My Profile",
    slug: "/profile",
    className: "drop-item",
    offset: 100,
  },
  {
    id: "logout",
    text: "logout",
    className: "drop-item",
    offset: 100,
  },
];

export const selectedValueTemplate = (option, props) => {
  if (option) {
    return <div> {option?.name ? option?.name : option?.lookup_value} </div>;
  }
  return <span> {props?.placeholder} </span>;
};

export const itemOptionTemplate = (option) => {
  return <div> {option?.name ? option?.name : option?.lookup_value} </div>;
};

export const sortByOptions = [
  "Sort by A - Z",
  "Sort by Z - A",
  "Sort by Cities",
];

export const sortByOptionsCity = ["Sort by A - Z", "Sort by Z - A"];
