import Cookies from "js-cookie";

export default function authHeader() {
  const user = Cookies.get("user_name");
  const accessToken = Cookies.get("access_token");

  if (user && accessToken) {
    return { Authorization: "Bearer " + accessToken };
  } else {
    return {};
  }
}
