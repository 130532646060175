import {
  SEARCH_DATA_FAILURE,
  SEARCH_DATA_REQUEST,
  SEARCH_DATA_SUCCESS,
} from "../types";
import axiosInstance from "../../services/axiosInstance";
import authHeader from "../../services/auth-header";
import recreateAccessTokenAction from "../auth/RecreateAccessTokenAction";

const searchApiAction = (data) => {
  return (dispatch) => {
    dispatch({
      type: SEARCH_DATA_REQUEST,
    });
    axiosInstance
      .post(`v1/search_entity/`, data, {
        headers: authHeader(),
      })
      .then((response) => {
        dispatch({
          type: SEARCH_DATA_SUCCESS,
          payload: response.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: SEARCH_DATA_FAILURE,
          payload: error.message,
        });
        if (error.response.status === 401) {
          dispatch(recreateAccessTokenAction());
        }
      });
  };
};

export default searchApiAction;
