import React, { Suspense, useEffect } from "react";
import { Outlet } from "react-router-dom";
import Cookies from "js-cookie";
import SocialContact from "../home/socialContact";
import Header from "../home/Header";
import Footer from "../home/Footer";
import CopyRightFooter from "../home/CopyRightFooter";

const Layout = () => {
  const is_auth = Cookies.get("access_token");

  useEffect(() => {
    if (Cookies.get("isLoggedIn") === "false") {
      Cookies.removeItem("email");
      Cookies.removeItem("user_id");
      Cookies.removeItem("username");
      Cookies.removeItem("access_token");
      Cookies.removeItem("refresh_token");
      Cookies.removeItem("logged_in_status");
      Cookies.removeItem("profile_image");
    }
  }, [Cookies.get("isLoggedIn")]);

  return (
    <div className={"overflow-hidden"}>
      {!is_auth && <SocialContact />}
      <Header />

      <div>
        <Suspense>
          <Outlet />
        </Suspense>
      </div>

      {!is_auth && <Footer />}
      <CopyRightFooter />
    </div>
  );
};

export default Layout;
