import {
  EDIT_GROUP_NAME_REQUEST,
  EDIT_GROUP_NAME_SUCCESS,
  EDIT_GROUP_NAME_FAILURE,
  RESET_STATE_FAVORITE,
} from "../../action/types";

const initialState = {
  data: [],
  loading: false,
  error: null,
};

const EditGroupNameReducer = (state = initialState, action) => {
  switch (action.type) {
    case EDIT_GROUP_NAME_REQUEST:
      return { ...state, loading: true, error: null };
    case EDIT_GROUP_NAME_SUCCESS:
      return { ...state, data: action.payload, loading: false, error: null };
    case EDIT_GROUP_NAME_FAILURE:
      return { ...state, loading: false, error: action.payload };
    case RESET_STATE_FAVORITE:
      return initialState;
    default:
      return state;
  }
};

export default EditGroupNameReducer;
