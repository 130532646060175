import { LOGOUT_REQUEST } from "../types";
import Cookies from "js-cookie";

const logoutAction = () => {
  const clearAllCookies = () => {
    // Get all cookie names
    const cookieNames = Cookies.get();
    // Iterate over each cookie name and remove it
    Object.keys(cookieNames).forEach((cookieName) => {
      Cookies.remove(cookieName);
    });
    // Optionally, update state or perform other actions after clearing all cookies
  };

  return async (dispatch) => {
    try {
      clearAllCookies();

      dispatch({
        type: LOGOUT_REQUEST,
        payload: { status: 401 },
      });
    } catch (e) {}
  };
};

export default logoutAction;
