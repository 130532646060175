import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAngleDown,
  faAngleRight,
  faBars,
  faEnvelope,
  faSearch,
  faXmark,
} from "@fortawesome/free-solid-svg-icons";
import Cookies from "js-cookie";
import resetState from "../../redux/action/common/ResetStateAction";
import ScrollLink from "../commonComponents/ScrollLink";
import FilterSearchBar from "../commonComponents/FilterSearchBar";
import SearchBarMobile from "../commonComponents/SearchBarMobile";
import classNames, {
  linksArray,
  profileArray,
} from "../commonComponents/utils";
import option from "../assets/images/common/logo_dark.png";
import logoLight from "../assets/images/common/logo_light.png";

const Header = () => {
  const [isSticky, setSticky] = useState(false);
  const [mobileMenu, setMobileMenu] = useState(false);
  const [searchBar, setSearchBar] = useState(false);
  const [isDropdownVisible, setDropdownVisible] = useState(false);
  const [profileImage, setProfileImage] = useState("");

  const { t } = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const is_auth = Cookies.get("access_token");
  const profile = useSelector((state) => state?.updateUserProfileReducer);

  const handleHomeClick = () => {
    window.scrollTo(0, 0);
    setMobileMenu(false);
  };

  useEffect(() => {
    const handleScroll = () => {
      const offset = window.scrollY;

      if (offset > 0) {
        setSticky(true);
      } else {
        setSticky(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    // Cleanup event listener on component unmount
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const pre_login_menu = [
    {
      id: 1,
      title: `${t("Partners")}`,
      link: "/partners",
    },
    {
      id: 2,
      title: `${t("Events")}`,
      link: "/events",
    },
    {
      id: 3,
      title: `${t("Contact_Us")}`,
      link: "/contact-us",
    },
    {
      id: 4,
      title: `${t("Sign_In")}`,
      link: "/signin",
    },
    {
      id: 5,
      title: `${t("Sign_Up")}`,
      link: "/signup",
    },
  ];

  const post_login_menu = [
    {
      id: 1,
      title: `${t("Community")}`,
      link: "/directory",
    },
    {
      id: 2,
      title: `${t("Favorite")}`,
      link: "/favorite",
    },
    {
      id: 3,
      title: `${t("Partners")}`,
      link: "/partners",
    },
    {
      id: 4,
      title: `${t("Events")}`,
      link: "/events",
    },
    {
      id: 5,
      title: `${t("Contact_Us")}`,
      link: "/contact-us",
    },
  ];

  const post_login_mobile_menu = [
    {
      id: 1,
      title: `${t("Community")}`,
      link: "/directory",
    },
    {
      id: 2,
      title: `${t("Favorite")}`,
      link: "/favorite",
    },
    {
      id: 3,
      title: `${t("Partners")}`,
      link: "/partners",
    },
    {
      id: 4,
      title: `${t("Events")}`,
      link: "/events",
    },
    {
      id: 5,
      title: `${t("Profile")}`,
      link: "/profile",
    },
    {
      id: 6,
      title: `${t("Contact_Us")}`,
      link: "/contact-us",
    },
  ];

  useEffect(() => {
    if (profile?.data?.status_code === 2000) {
      setProfileImage(localStorage.getItem("profile_image"));
    } else {
      setProfileImage(localStorage.getItem("profile_image"));
    }
  }, [profile, localStorage.getItem("profile_image")]);

  const clearAllCookies = () => {
    // Get all cookie names
    const cookieNames = Cookies.get();
    // Iterate over each cookie name and remove it
    Object.keys(cookieNames).forEach((cookieName) => {
      Cookies.remove(cookieName);
    });
    // Optionally, update state or perform other actions after clearing all cookies
  };

  return (
    <div className={"relative"}>
      <div
        className={classNames(
          isSticky ? "md:fixed md:top-0 relative w-full bg-white" : "",
          "flex items-center justify-between px-[100px] max-[1599px]:px-4 shadow akarma-header z-20"
        )}
      >
        <div className={"flex items-center p-2"}>
          <Link
            to={Cookies.get("access_token") ? "/directory" : "/"}
            onClick={handleHomeClick}
            target={"_blank"}
          >
            <img src={option} alt={"Akarma"} width={"200"} />
          </Link>
        </div>

        <div className={"!min-w-[30%] mx-2 sm:block hidden"}>
          <FilterSearchBar />
        </div>

        <div className={"flex items-center"}>
          <ul className={"min-[1200px]:flex hidden items-center mr-5"}>
            {!is_auth ? (
              <>
                <li className={"about-drop-down"}>
                  <div
                    className={
                      "text-cleenheartsBase font-bold ml-5 cursor-pointer"
                    }
                  >
                    {t("About")}
                  </div>

                  <div className={"dropdown-content"}>
                    {linksArray?.map((link, index) => {
                      return (
                        <div key={index}>
                          <ScrollLink
                            to={`/#${link.id}`}
                            className={"drop-item"}
                            offset={
                              document.documentElement.scrollTop === 0
                                ? 200
                                : 100
                            }
                          >
                            {link.text}
                          </ScrollLink>
                        </div>
                      );
                    })}
                  </div>
                </li>

                {pre_login_menu?.map((menu) => (
                  <li key={menu?.id}>
                    <Link
                      to={menu.link}
                      className={classNames(
                        location.pathname === menu.link
                          ? "text-cleenheartsWhite2 bg-cleenheartsPurple rounded-[20px] py-1.5 px-2.5 font-medium"
                          : "text-cleenheartsBase hover:text-cleenheartsPurple",
                        "font-bold ml-5"
                      )}
                    >
                      {menu.title}
                    </Link>
                  </li>
                ))}
              </>
            ) : (
              <>
                {post_login_menu?.map((menu) => (
                  <li key={menu?.id}>
                    <Link
                      to={menu?.link}
                      className={classNames(
                        location.pathname === menu?.link ||
                          (location.pathname === "/directory/f" &&
                            menu?.title === "Directory")
                          ? "text-cleenheartsWhite2 bg-cleenheartsPurple rounded-[20px] py-1.5 px-2.5 font-medium"
                          : "text-cleenheartsBase hover:text-cleenheartsPurple",
                        menu?.title === "Profile" &&
                          (Cookies.get("join_as") !== "guest" ? "" : "hidden"),
                        "font-bold ml-5"
                      )}
                      target={
                        (menu.title === "Directory" &&
                          location.pathname === "/directory/f") ||
                        (menu.title === "Favorite" &&
                          location.pathname === "/directory/f")
                          ? "_blank"
                          : "_self"
                      }
                    >
                      {menu?.title}
                    </Link>
                  </li>
                ))}

                <li className={"about-drop-down"}>
                  <div
                    className={
                      "text-cleenheartsBase font-bold ml-5 cursor-pointer"
                    }
                  >
                    <img
                      src={profileImage}
                      alt={""}
                      className={"rounded-full ml-1"}
                      width={40}
                      height={40}
                    />
                  </div>

                  <div className={"user-dropdown-content"}>
                    <p className={"text-black font-medium text-center my-1"}>
                      {Cookies.get("first_name")}
                    </p>

                    <hr />

                    {profileArray?.map((link, index) => {
                      return (
                        <div key={index}>
                          {link.text === "logout" ? (
                            <div
                              onClick={() => {
                                clearAllCookies();
                                navigate("/");
                                dispatch(resetState());
                              }}
                              className={"drop-item cursor-pointer"}
                              offset={100}
                            >
                              {link.text}
                            </div>
                          ) : (
                            <Link
                              to={link?.slug}
                              className={"drop-item cursor-pointer"}
                            >
                              {link?.text}
                            </Link>
                          )}
                        </div>
                      );
                    })}
                  </div>
                </li>
              </>
            )}
          </ul>

          <div className={"flex items-center"}>
            <div className={"sm:hidden flex cursor-pointer mr-4"}>
              <FontAwesomeIcon
                icon={faSearch}
                size={"xl"}
                onClick={() => setSearchBar(!searchBar)}
              />
            </div>

            <div className={"min-[1200px]:hidden flex cursor-pointer"}>
              <FontAwesomeIcon
                icon={faBars}
                size={"xl"}
                onClick={() => setMobileMenu(!mobileMenu)}
              />
            </div>
          </div>
        </div>
      </div>

      {mobileMenu && (
        <div
          className={classNames(
            mobileMenu ? "expanded min-[1200px]:hidden flex" : "hidden",
            "mobile-nav__wrapper"
          )}
        >
          <div
            className={
              "absolute top-0 right-0 left-0 bottom-0 bg-cleenheartsDark opacity-70 cursor-[url(./components/assets/images/common/close.png),_pointer]"
            }
            onClick={() => setMobileMenu(false)}
          />

          <div className={"mobile-nav__content"}>
            <div className={"flex items-center justify-between mb-10"}>
              <Link to={"/"} onClick={handleHomeClick} target={"_blank"}>
                <img src={logoLight} width={"155px"} alt={""} />
              </Link>

              <div className={"absolute right-3 top-6 cursor-pointer"}>
                <FontAwesomeIcon
                  icon={faXmark}
                  size={"lg"}
                  className={"text-white hover:!text-cleenheartsSecondary"}
                  onClick={() => setMobileMenu(false)}
                />
              </div>
            </div>

            <ul>
              {is_auth ? (
                <>
                  <li
                    className={
                      "text-white hover:text-cleenheartsSecondary font-bold mb-2"
                    }
                  >
                    Hi, {Cookies.get("first_name")}
                  </li>
                  <hr className={"m-0 border-t-white opacity-10"} />

                  {post_login_mobile_menu?.map((menu) => (
                    <div key={menu?.id}>
                      <li
                        className={classNames(
                          location.pathname === menu?.link ||
                            (location.pathname === "/directory/f" &&
                              menu?.title === "Directory")
                            ? "text-cleenheartsSecondary"
                            : "text-white hover:text-cleenheartsSecondary",
                          "font-bold my-2"
                        )}
                      >
                        <Link
                          to={menu?.link}
                          className={"text-inherit"}
                          target={
                            (menu.title === "Directory" &&
                              location.pathname === "/directory/f") ||
                            (menu.title === "Favorite" &&
                              location.pathname === "/directory/f")
                              ? "_blank"
                              : "_self"
                          }
                        >
                          {menu?.title}
                        </Link>
                      </li>
                      <hr className={"m-0 border-t-white opacity-10"} />
                    </div>
                  ))}

                  <li>
                    <div
                      onClick={() => {
                        clearAllCookies();
                        navigate("/");
                        dispatch(resetState());
                      }}
                      className={
                        "text-white hover:text-cleenheartsSecondary font-bold cursor-pointer my-2"
                      }
                    >
                      Logout
                    </div>
                    <hr className={"m-0 border-t-white opacity-10"} />
                  </li>
                </>
              ) : (
                <>
                  <li
                    className={classNames(
                      location.hash === "/#about"
                        ? "text-cleenheartsSecondary"
                        : "text-white hover:text-cleenheartsSecondary",
                      "font-bold my-2 about-tab-title d-flex justify-between"
                    )}
                  >
                    <div onClick={() => setMobileMenu(false)}>
                      <ScrollLink
                        to={"/#about"}
                        className={"text-inherit"}
                        mobileMenu={mobileMenu}
                        offset={100}
                      >
                        About
                      </ScrollLink>
                    </div>

                    <button
                      aria-label={"dropdown toggler"}
                      className={"bg-cleenheartsSecondary px-2 text-dark"}
                      onClick={() => setDropdownVisible(!isDropdownVisible)}
                    >
                      <FontAwesomeIcon
                        icon={isDropdownVisible ? faAngleDown : faAngleRight}
                      />
                    </button>
                  </li>

                  <hr className={"m-0 border-t-white opacity-10"} />

                  {isDropdownVisible && (
                    <div className={"pl-3"}>
                      <li
                        className={"text-cleenheartsSecondary font-bold"}
                        onClick={() => setMobileMenu(false)}
                      >
                        {linksArray?.map((link, index) => {
                          return (
                            <div className={"py-1"} key={index}>
                              <ScrollLink
                                to={`/#${link.id}`}
                                className={"drop-item"}
                                offset={100}
                                mobileMenu={mobileMenu}
                              >
                                {link.text}
                              </ScrollLink>
                            </div>
                          );
                        })}
                      </li>
                    </div>
                  )}

                  {pre_login_menu?.map((menu) => (
                    <div key={menu?.id}>
                      <li
                        className={classNames(
                          location.pathname === menu?.link
                            ? "text-cleenheartsSecondary"
                            : "text-white hover:text-cleenheartsSecondary",
                          "font-bold my-2"
                        )}
                      >
                        <Link to={menu?.link} className={"text-inherit"}>
                          {menu?.title}
                        </Link>
                      </li>
                      <hr className={"m-0 border-t-white opacity-10"} />
                    </div>
                  ))}
                </>
              )}
            </ul>

            <ul className={"my-4"}>
              <li className={"flex items-center"}>
                <div
                  className={
                    "bg-cleenheartsSecondary w-[30px] h-[30px] rounded-[50%] mr-2 flex items-center justify-center"
                  }
                >
                  <FontAwesomeIcon
                    icon={faEnvelope}
                    className={"text-cleenheartsBase"}
                    size={"sm"}
                  />
                </div>
                <a href={"mailto:contact@akarma.in"} className={"text-white"}>
                  contact@akarma.in
                </a>
              </li>
            </ul>
          </div>
        </div>
      )}

      {searchBar && (
        <SearchBarMobile searchBar={searchBar} setSearchBar={setSearchBar} />
      )}
    </div>
  );
};

export default Header;
