import React from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope } from "@fortawesome/free-solid-svg-icons";
import ScrollLink from "../commonComponents/ScrollLink";
import option from "../assets/images/common/logo_dark.png";

const Footer = () => {
  const { t } = useTranslation();

  return (
    <div className={"relative overflow-hidden bg-cleenheartsWhite2"}>
      {/* menu items footer */}
      <div className={"relative"}>
        <div className={"py-7 max-w-6xl mx-auto w-[60%]"}>
          <div className={"flex flex-wrap justify-between"}>
            <div className={"px-4 min-[1200px]:w-[25%] w-full"}>
              <div className={"mb-7"}>
                <Link to={"/"} onClick={() => window.scrollTo(0, 0)}>
                  <img
                    src={option}
                    width={"159"}
                    alt={"Akarma Logo"}
                    className={"mb-10"}
                  />
                </Link>
                <p className={"text-cleenheartsText leading-[1.75]"}>
                  {t("footer_text")}
                </p>
              </div>
            </div>

            <div className={"px-4 min-[1200px]:w-[25%] w-full"}>
              <h2 className={"footer-widget__title"}>{t("footer_title_1")}</h2>

              <ul>
                <li className={"flex items-center text-cleenheartsText mt-3"}>
                  <FontAwesomeIcon
                    icon={faEnvelope}
                    className={"text-cleenheartsPurple mr-2"}
                    size={"1x"}
                  />
                  <div className={"relative"}>
                    <a
                      href={"mailto:contact@akarma.in"}
                      className={
                        "hover:text-cleenheartsBase hover-underline-animation-footer text-inherit"
                      }
                    >
                      contact@akarma.in
                    </a>
                  </div>
                </li>
              </ul>
            </div>

            <div className={"px-4 min-[1200px]:w-[25%] w-full"}>
              <h2 className={"footer-widget__title__Links"}>
                {t("footer_title_2")}
              </h2>

              <ul>
                <li className={"flex items-center text-cleenheartsText"}>
                  <div className={"relative"}>
                    <ScrollLink
                      footerLink={true}
                      to={"/#about"}
                      className={
                        "hover:text-cleenheartsBase hover-underline-animation-footer text-inherit"
                      }
                    >
                      {t("About")}
                    </ScrollLink>
                  </div>
                </li>

                <li className={"flex items-center text-cleenheartsText mt-2"}>
                  <div className={"relative"}>
                    <Link
                      to={"/partners"}
                      className={
                        "hover:text-cleenheartsBase hover-underline-animation-footer text-inherit"
                      }
                    >
                      {t("Partners")}
                    </Link>
                  </div>
                </li>

                <li className={"flex items-center text-cleenheartsText mt-2"}>
                  <div className={"relative"}>
                    <Link
                      to={"/contact-us"}
                      className={
                        "hover:text-cleenheartsBase hover-underline-animation-footer text-inherit"
                      }
                    >
                      {t("Contact_Us")}
                    </Link>
                  </div>
                </li>

                <li className={"flex items-center text-cleenheartsText mt-2"}>
                  <div className={"relative"}>
                    <Link
                      to={"/signin"}
                      className={
                        "hover:text-cleenheartsBase hover-underline-animation-footer text-inherit"
                      }
                    >
                      {t("Sign_In")}
                    </Link>
                  </div>
                </li>

                <li className={"flex items-center text-cleenheartsText mt-2"}>
                  <div className={"relative"}>
                    <Link
                      to={"/signup"}
                      className={
                        "hover:text-cleenheartsBase hover-underline-animation-footer text-inherit"
                      }
                    >
                      {t("Sign_Up")}
                    </Link>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
