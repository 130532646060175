// Auth
export const LOGIN_REQUEST = "LOGIN_REQUEST";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAILURE = "LOGIN_FAILURE";

export const RECREATE_ACCESS_TOKEN_REQUEST = "RECREATE_ACCESS_TOKEN_REQUEST";
export const RECREATE_ACCESS_TOKEN_SUCCESS = "RECREATE_ACCESS_TOKEN_SUCCESS";
export const RECREATE_ACCESS_TOKEN_FAILURE = "RECREATE_ACCESS_TOKEN_FAILURE";

export const LOGOUT_REQUEST = "LOGOUT_REQUEST";
export const LOGOUT_SUCCESS = "LOGOUT_SUCCESS";
export const LOGOUT_FAILURE = "LOGOUT_FAILURE";

export const SIGNUP_REQUEST = "SIGNUP_REQUEST";
export const SIGNUP_SUCCESS = "SIGNUP_SUCCESS";
export const SIGNUP_FAILURE = "SIGNUP_FAILURE";

export const FORGOT_PASSWORD_REQUEST = "FORGOT_PASSWORD_REQUEST";
export const FORGOT_PASSWORD_SUCCESS = "FORGOT_PASSWORD_SUCCESS";
export const FORGOT_PASSWORD_FAILURE = "FORGOT_PASSWORD_FAILURE";

export const RESET_PASSWORD_REQUEST = "RESET_PASSWORD_REQUEST";
export const RESET_PASSWORD_SUCCESS = "RESET_PASSWORD_SUCCESS";
export const RESET_PASSWORD_FAILURE = "RESET_PASSWORD_FAILURE";

// Common
export const RESET_STATE = "RESET_STATE ";
export const RESET_STATE_FAVORITE = "RESET_STATE_FAVORITE ";

export const LOOKUP_REQUEST = "LOOKUP_REQUEST";
export const LOOKUP_SUCCESS = "LOOKUP_SUCCESS";
export const LOOKUP_FAILURE = "LOOKUP_FAILURE";

export const COUNTRY_REQUEST = "COUNTRY_REQUEST";
export const COUNTRY_SUCCESS = "COUNTRY_SUCCESS";
export const COUNTRY_FAILURE = "COUNTRY_FAILURE";

export const STATE_CITY_REQUEST = "STATE_CITY_REQUEST";
export const STATE_CITY_SUCCESS = "STATE_CITY_SUCCESS";
export const STATE_CITY_FAILURE = "STATE_CITY_FAILURE";

// profile
export const PINCODE_REQUEST = "PINCODE_REQUEST";
export const PINCODE_SUCCESS = "PINCODE_SUCCESS";
export const PINCODE_FAILURE = "PINCODE_FAILURE";

export const GET_USER_PROFILE_REQUEST = "GET_USER_PROFILE_REQUEST";
export const GET_USER_PROFILE_SUCCESS = "GET_USER_PROFILE_SUCCESS";
export const GET_USER_PROFILE_FAILURE = "GET_USER_PROFILE_FAILURE";

export const UPDATE_USER_PROFILE_REQUEST = "UPDATE_USER_PROFILE_REQUEST";
export const UPDATE_USER_PROFILE_SUCCESS = "UPDATE_USER_PROFILE_SUCCESS";
export const UPDATE_USER_PROFILE_FAILURE = "UPDATE_USER_PROFILE_FAILURE";

// Details pages
export const TAB_LIST_REQUEST = "TAB_LIST_REQUEST";
export const TAB_LIST_SUCCESS = "TAB_LIST_SUCCESS";
export const TAB_LIST_FAILURE = "TAB_LIST_FAILURE";

export const SUMMARY_DATA_REQUEST = "SUMMARY_DATA_REQUEST";
export const SUMMARY_DATA_SUCCESS = "SUMMARY_DATA_SUCCESS";
export const SUMMARY_DATA_FAILURE = "SUMMARY_DATA_FAILURE";

export const DEMOGRAPHICS_DATA_REQUEST = "DEMOGRAPHICS_DATA_REQUEST";
export const DEMOGRAPHICS_DATA_SUCCESS = "DEMOGRAPHICS_DATA_SUCCESS";
export const DEMOGRAPHICS_DATA_FAILURE = "DEMOGRAPHICS_DATA_FAILURE";

export const AREAOFWORK_DATA_REQUEST = "AREAOFWORK_DATA_REQUEST";
export const AREAOFWORK_DATA_SUCCESS = "AREAOFWORK_DATA_SUCCESS";
export const AREAOFWORK_DATA_FAILURE = "AREAOFWORK_DATA_FAILURE";

export const TRUSTEESMEMBERSDIRECTORS_DATA_REQUEST =
  "TRUSTEESMEMBERSDIRECTORS_DATA_REQUEST";
export const TRUSTEESMEMBERSDIRECTORS_DATA_SUCCESS =
  "TRUSTEESMEMBERSDIRECTORS_DATA_SUCCESS";
export const TRUSTEESMEMBERSDIRECTORS_DATA_FAILURE =
  "TRUSTEESMEMBERSDIRECTORS_DATA_FAILURE";

export const COMPANY_PROFILE_DATA_REQUEST = "COMPANY_PROFILE_DATA_REQUEST";
export const COMPANY_PROFILE_DATA_SUCCESS = "COMPANY_PROFILE_DATA_SUCCESS";
export const COMPANY_PROFILE_DATA_FAILURE = "COMPANY_PROFILE_DATA_FAILURE";

export const DIRECTORS_DATA_REQUEST = "DIRECTORS_DATA_REQUEST";
export const DIRECTORS_DATA_SUCCESS = "DIRECTORS_DATA_SUCCESS";
export const DIRECTORS_DATA_FAILURE = "DIRECTORS_DATA_FAILURE";

export const CSR_INFO_DATA_REQUEST = "CSR_INFO_DATA_REQUEST";
export const CSR_INFO_DATA_SUCCESS = "CSR_INFO_DATA_SUCCESS";
export const CSR_INFO_DATA_FAILURE = "CSR_INFO_DATA_FAILURE";

export const INDIVIDUAL_PROFILE_TAB_DATA_REQUEST =
  "INDIVIDUAL_PROFILE_TAB_DATA_REQUEST";
export const INDIVIDUAL_PROFILE_TAB_DATA_SUCCESS =
  "INDIVIDUAL_PROFILE_TAB_DATA_SUCCESS";
export const INDIVIDUAL_PROFILE_TAB_DATA_FAILURE =
  "INDIVIDUAL_PROFILE_TAB_DATA_FAILURE";

export const CONNECTION_DATA_REQUEST = "CONNECTION_DATA_REQUEST";
export const CONNECTION_DATA_SUCCESS = "CONNECTION_DATA_SUCCESS";
export const CONNECTION_DATA_FAILURE = "CONNECTION_DATA_FAILURE";

export const SUPPORT_DATA_REQUEST = "SUPPORT_DATA_REQUEST";
export const SUPPORT_DATA_SUCCESS = "SUPPORT_DATA_SUCCESS";
export const SUPPORT_DATA_FAILURE = "SUPPORT_DATA_FAILURE";

// Contact us
export const CONTACT_US_REQUEST = "CONTACT_US_REQUEST";
export const CONTACT_US_SUCCESS = "CONTACT_US_SUCCESS";
export const CONTACT_US_FAILURE = "CONTACT_US_FAILURE";

// searchbar
export const SEARCH_DATA_FAILURE = "SEARCH_DATA_FAILURE";
export const SEARCH_DATA_REQUEST = "SEARCH_DATA_REQUEST";
export const SEARCH_DATA_SUCCESS = "SEARCH_DATA_SUCCESS";

// smart search community
export const FILTERS_REQUEST = "FILTER_DATA_REQUEST";
export const FILTERS_SUCCESS = "FILTER_DATA_SUCCESS";
export const FILTERS_FAILURE = "FILTER_DATA_FAILURE";

export const FILTER_RESPONSE_DATA_REQUEST = "FILTER_RESPONSE_DATA_REQUEST";
export const FILTER_RESPONSE_DATA_SUCCESS = "FILTER_RESPONSE_DATA_SUCCESS";
export const FILTER_RESPONSE_DATA_FAILURE = "FILTER_RESPONSE_DATA_FAILURE";

export const SMART_SEARCH_LOCATION_REQUEST = "SMART_SEARCH_LOCATION_REQUEST";
export const SMART_SEARCH_LOCATION_SUCCESS = "SMART_SEARCH_LOCATION_SUCCESS";
export const SMART_SEARCH_LOCATION_FAILURE = "SMART_SEARCH_LOCATION_FAILURE";

// Favourite
export const FAVORITE_REQUEST = "FAVORITE_REQUEST";
export const FAVORITE_SUCCESS = "FAVORITE_SUCCESS";
export const FAVORITE_FAILURE = "FAVORITE_FAILURE";

export const FAVORITE_DATA_REQUEST = "FAVORITE_DATA_REQUEST";
export const FAVORITE_DATA_SUCCESS = "FAVORITE_DATA_SUCCESS";
export const FAVORITE_DATA_FAILURE = "FAVORITE_DATA_FAILURE";

export const GROUP_LIST_REQUEST = "GROUP_LIST_REQUEST";
export const GROUP_LIST_SUCCESS = "GROUP_LIST_SUCCESS";
export const GROUP_LIST_FAILURE = "GROUP_LIST_FAILURE";

export const CREATE_LIST_REQUEST = "CREATE_LIST_REQUEST";
export const CREATE_LIST_SUCCESS = "CREATE_LIST_SUCCESS";
export const CREATE_LIST_FAILURE = "CREATE_LIST_FAILURE";

export const CHANGE_LIST_REQUEST = "CHANGE_LIST_REQUEST";
export const CHANGE_LIST_SUCCESS = "CHANGE_LIST_SUCCESS";
export const CHANGE_LIST_FAILURE = "CHANGE_LIST_FAILURE";

export const EDIT_GROUP_NAME_REQUEST = "EDIT_GROUP_NAME_REQUEST";
export const EDIT_GROUP_NAME_SUCCESS = "EDIT_GROUP_NAME_SUCCESS";
export const EDIT_GROUP_NAME_FAILURE = "EDIT_GROUP_NAME_FAILURE";

export const DELETE_GROUP_NAME_REQUEST = "DELETE_GROUP_NAME_REQUEST";
export const DELETE_GROUP_NAME_SUCCESS = "DELETE_GROUP_NAME_SUCCESS";
export const DELETE_GROUP_NAME_FAILURE = "DELETE_GROUP_NAME_FAILURE";

// Dashboard
export const DASHBOARD_REQUEST = "DASHBOARD_REQUEST";
export const DASHBOARD_SUCCESS = "DASHBOARD_SUCCESS";
export const DASHBOARD_FAILURE = "DASHBOARD_FAILURE";

// Verification
export const RESENT_EMAIL_REQUEST = "RESENT_EMAIL_REQUEST";
export const RESENT_EMAIL_SUCCESS = "RESENT_EMAIL_SUCCESS";
export const RESENT_EMAIL_FAILURE = "RESENT_EMAIL_FAILURE";

export const EMAIL_VERIFY_REQUEST = "EMAIL_VERIFY_REQUEST";
export const EMAIL_VERIFY_SUCCESS = "EMAIL_VERIFY_SUCCESS";
export const EMAIL_VERIFY_FAILURE = "EMAIL_VERIFY_FAILURE";

export const SEND_MOBILE_OTP_REQUERST = "SEND_MOBILE_OTP_REQUERST";
export const SEND_MOBILE_OTP_SUCCESS = "SEND_MOBILE_OTP_SUCCESS";
export const SEND_MOBILE_OTP_FAILURE = "SEND_MOBILE_OTP_FAILURE";

export const VERIFY_OTP_REQUEST = "VERIFY_OTP_REQUEST";
export const VERIFY_OTP_SUCCESS = "VERIFY_OTP_SUCCESS";
export const VERIFY_OTP_FAILURE = "VERIFY_OTP_FAILURE";
