import {
  RESET_PASSWORD_REQUEST,
  RESET_PASSWORD_SUCCESS,
  RESET_PASSWORD_FAILURE,
  RESET_STATE,
} from "../../action/types";

const initialState = {
  data: [],
  loading: false,
  error: null,
};

const resetReducer = (state = initialState, action) => {
  switch (action.type) {
    case RESET_PASSWORD_REQUEST:
      return { ...state, loading: true, error: null };
    case RESET_PASSWORD_SUCCESS:
      return { ...state, data: action.payload, loading: false, error: null };
    case RESET_PASSWORD_FAILURE:
      return { ...state, loading: false, error: action.payload };
    case RESET_STATE:
      return initialState;
    default:
      return state;
  }
};

export default resetReducer;
