import {
  UPDATE_USER_PROFILE_REQUEST,
  UPDATE_USER_PROFILE_SUCCESS,
  UPDATE_USER_PROFILE_FAILURE,
  RESET_STATE,
} from "../../action/types";

const initialState = {
  data: [],
  loading: false,
  error: null,
};

const UpdateUserProfileReducer = (state = initialState, action) => {
  switch (action.type) {
    case UPDATE_USER_PROFILE_REQUEST:
      return { ...state, loading: true, error: null };
    case UPDATE_USER_PROFILE_SUCCESS:
      return { ...state, data: action.payload, loading: false, error: null };
    case RESET_STATE:
      return initialState;
    case UPDATE_USER_PROFILE_FAILURE:
      return { ...state, loading: false, error: action.payload };
    default:
      return state;
  }
};

export default UpdateUserProfileReducer;
