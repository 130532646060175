import {
  SEARCH_DATA_REQUEST,
  SEARCH_DATA_SUCCESS,
  SEARCH_DATA_FAILURE,
  RESET_STATE,
} from "../../action/types";

const initialState = {
  data: [],
  loading: false,
  error: null,
};

const searchApiReducer = (state = initialState, action) => {
  switch (action.type) {
    case SEARCH_DATA_REQUEST:
      return { ...state, loading: true, error: null };
    case SEARCH_DATA_SUCCESS:
      return { ...state, data: action.payload, loading: false, error: null };
    case RESET_STATE:
      return initialState;
    case SEARCH_DATA_FAILURE:
      return { ...state, loading: false, error: action.payload };
    default:
      return state;
  }
};

export default searchApiReducer;
