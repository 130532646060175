import React from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import classNames from "./utils";

const ScrollLink = ({
  to,
  children,
  offset,
  className,
  mobileMenu,
  footerLink,
}) => {
  const location = useLocation();
  const navigate = useNavigate();

  const handleClick = (e) => {
    navigate("/");
    e.preventDefault();
    if (location.pathname === "/") {
      const hash = to?.slice(1);
      const target = document.querySelector(hash);
      const scrollTop =
        window.pageYOffset || document.documentElement.scrollTop;
      const targetTop = target
        ? target.getBoundingClientRect().top + scrollTop
        : 0;

      window.scrollTo({
        top: targetTop - (offset || 0),
        behavior: "smooth",
      });
    } else {
      setTimeout(() => {
        const hash = to.slice(1);
        const target = document.querySelector(hash);
        const scrollTop =
          window.pageYOffset || document.documentElement.scrollTop;
        const targetTop = target
          ? target.getBoundingClientRect().top + scrollTop
          : 0;

        window.scrollTo({
          top: targetTop - (offset || 0),
          behavior: "smooth",
        });
      }, 1000);
    }
  };

  return mobileMenu ? (
    <Link to={to} onClick={handleClick} className={"text-white"}>
      {children}
    </Link>
  ) : (
    <Link
      to={to}
      onClick={handleClick}
      className={
        footerLink
          ? classNames(className)
          : classNames(
              className === "drop-item" ? "drop-item" : "font-bold ml-5"
            )
      }
    >
      {children}
    </Link>
  );
};

export default ScrollLink;
