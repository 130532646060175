import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBuilding,
  faHandHoldingHeart,
  faLocationDot,
  faUser,
} from "@fortawesome/free-solid-svg-icons";
import Cookies from "js-cookie";
import searchApiAction from "../../redux/action/smartsearch/searchApiAction";
import classNames from "./utils";

const FilterSearchBar = () => {
  const [listWidth, setListWidth] = useState("");
  const [listShow, setListShow] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [debouncedSearch, setDebouncedSearch] = useState("");

  const { t } = useTranslation();
  const textBoxRef = useRef(null);
  const searchBoxRef = useRef(null);
  const dispatch = useDispatch();
  const searchApiData = useSelector((state) => state.searchApiReducer);
  const is_auth = Cookies.get("access_token");

  useEffect(() => {
    // This effect will run when searchTerm changes
    const timeoutId = setTimeout(() => {
      setDebouncedSearch(searchTerm);
    }, 1000);

    // Cleanup function to clear the timeout when searchTerm changes
    return () => {
      clearTimeout(timeoutId);
    };
  }, [searchTerm]);

  useEffect(() => {
    // This effect will run when debouncedSearch changes
    if (debouncedSearch.length >= 3) {
      dispatch(
        searchApiAction({
          search_str: debouncedSearch,
          entity_type: is_auth ? "all" : "ngo",
        })
      );
    }
  }, [debouncedSearch]);

  const handleChange = (e) => {
    const { value } = e.target;

    setSearchTerm(value);

    if (value.length >= 3) {
      handleResize();
      setListShow(true);
    } else if (value === "") {
      setListShow(false);
    }
  };

  const handleResize = () => {
    if (textBoxRef.current) {
      const textBoxWidth = textBoxRef.current.getBoundingClientRect().width;
      setListWidth(textBoxWidth);
    }
  };

  useEffect(() => {
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  // Function to handle click outside the search box
  const handleClickOutside = (event) => {
    if (searchBoxRef.current && !searchBoxRef.current.contains(event.target)) {
      setListShow(false);
    }
  };

  // Attach click outside handler to document
  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <div ref={searchBoxRef}>
      <div className={"search-container flex w-full"}>
        <form className={"no-submit w-100"}>
          <input
            className={"no-submit focus:!border-cleenheartsBase focus:!shadow-none focus:!ring-cleenheartsBase"}
            type={"search"}
            placeholder={
              is_auth
                ? "Select People, NGO, CSR Company by name"
                : `${t("Search_by_NGO")}`
            }
            onChange={handleChange}
            ref={textBoxRef}
            title={
              is_auth
                ? "Select People, NGO, CSR Company by name"
                : "Search by NGO"
            }
          />
        </form>
      </div>

      {listShow && (
        <ul
          id={"ui-id-1"}
          tabIndex={0}
          className={classNames(
            searchApiData?.loading
              ? "block"
              : searchApiData?.data?.length === 0
              ? "hidden"
              : searchApiData?.data?.result?.length === undefined ||
                searchApiData?.data?.result?.length < 6
              ? "block h-auto"
              : "block h-80",
            "ui-menu ui-widget ui-widget-content ui-autocomplete absolute mt-1 z-[100] overflow-y-auto"
          )}
          style={{ width: listWidth }}
          unselectable={"on"}
        >
          {searchApiData?.data?.result?.length > 0 ? (
            searchApiData?.data?.result?.map((item) => (
              <>
                <a
                  href={`/details/${
                    item?.data_flag === 1
                      ? "NGO"
                      : item.data_flag === 3
                      ? "COMPANY"
                      : (item?.data_flag === 2 || item?.data_flag === 4) &&
                        "PEOPLE"
                  }/${item?.data_flag}/${item?.slug_name}/${item?.slug_key}`}
                  target={"_blank"}
                >
                  <li className={"ui-menu-item p-1 flex justify-between cursor-pointer"}>
                    <div className={"flex-col w-full"}>
                      <div className={"text-sm"}> {item?.name} </div>

                      {(item?.ciy_name || item?.state_name) && (
                        <div className={"text-xs mt-1"}>
                          {item?.city_name === null &&
                          item?.state_name === null ? (
                            ""
                          ) : (
                            <FontAwesomeIcon
                              icon={faLocationDot}
                              className={"mr-1"}
                            />
                          )}
                          {item?.city_name === null ? "" : item?.city_name}
                          {item?.city_name === null || item?.state_name === null
                            ? ""
                            : `,${" "}`}
                          {item?.state_name === null ? "" : item?.state_name}
                        </div>
                      )}

                      {(item?.master_entity_name !== null ||
                        item?.master_entity_name !== "-") && (
                        <div className={"text-xs"}>
                          {item?.master_entity_name !== null && (
                            <>
                              <FontAwesomeIcon
                                icon={
                                  item?.data_flag === 4
                                    ? faBuilding
                                    : faHandHoldingHeart
                                }
                                className={"mr-1"}
                              />
                              {item?.master_entity_name}
                            </>
                          )}
                        </div>
                      )}
                    </div>

                    <div className={"text-2xl"}>
                      <FontAwesomeIcon
                        icon={
                          item?.data_flag === 1
                            ? faHandHoldingHeart
                            : item?.data_flag === 3
                            ? faBuilding
                            : (item?.data_flag === 2 ||
                                item?.data_flag === 4) &&
                              faUser
                        }
                        className={"mr-1"}
                      />
                    </div>
                  </li>
                </a>
                <hr />
              </>
            ))
          ) : searchApiData.data?.status_code === 2040 ||
            searchApiData.data?.status_code === 5000 ? (
            <li
              className={classNames(
                searchApiData.data?.status_code === 5000
                  ? "text-red-400"
                  : "text-black",
                "p-1 flex justify-center"
              )}
            >
              {searchApiData?.data?.message}
            </li>
          ) : (
            searchApiData?.loading && (
              <li className={"p-1 flex justify-center items-center text-black"}>
                <div className={"loader-textbox"} />
              </li>
            )
          )}
        </ul>
      )}
    </div>
  );
};

export default FilterSearchBar;
