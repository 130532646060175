import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import Cookies from "js-cookie";
import classNames from "../commonComponents/utils";
import copy_right_footer from "../assets/images/backgrounds/copy_right_footer.png";

const CopyRightFooter = () => {
  const [isVisible, setIsVisible] = useState(false);
  const [scrollPercentage, setScrollPercentage] = useState(0);

  const { t } = useTranslation();
  const is_auth = Cookies.get("access_token");
  const currentYear = new Date().getFullYear();

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  const handleScroll = () => {
    const scrollHeight =
      document.documentElement.scrollHeight - window.innerHeight;
    const scrollY = window.scrollY;
    const percentage = (scrollY / scrollHeight) * 100;
    // You can adjust the scroll threshold as needed
    const scrollThreshold = 200;

    setScrollPercentage(percentage);
    setIsVisible(scrollY > scrollThreshold);
  };

  useEffect(() => {
    // Attach the scroll event listener when the component mounts
    window.addEventListener("scroll", handleScroll);

    // Remove the event listener when the component unmounts
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <div>
      <div className={"relative py-2 bg-cleenheartsSecondary"}>
        <div
          className={"left-0 bottom-0 w-full h-full mix-blend-multiply"}
          style={{ backgroundImage: `url(${copy_right_footer})` }}
        />

        <div className={"flex items-center"}>
          {(Cookies.get("isLoggedIn") === "true" || is_auth) && (
            <div className={"w-fit ml-4"}>
              <Link
                to={"/"}
                target={"_blank"}
                className={"text-sm font-semibold text-cleenheartsBase"}
              >
                {t("about_akarma_heading")}
              </Link>
            </div>
          )}

          <div className={"mx-auto"}>
            <p className={"text-sm font-semibold text-cleenheartsBase"}>
              &copy; {t("Copyright")} {currentYear} {t("copyright_text")}
            </p>
          </div>
        </div>
      </div>

      <div
        onClick={scrollToTop}
        className={classNames(
          isVisible ? "opacity-100 visible" : "opacity-0 hidden",
          "flex items-center w-auto h-[35px] bg-transparent fixed bottom-[60px] -right-3 z-[99] text-center cursor-pointer -rotate-90 transition-all ease-in"
        )}
      >
        <span
          className={"text-xs uppercase text-cleenheartsBase font-bold mr-2"}
        >
          {t("back_top")}
        </span>
        <span
          className={
            "inline-block w-[30px] h-1 bg-cleenheartsBase relative overflow-hidden"
          }
        >
          <span
            className={
              "absolute left-0 top-0 w-full h-full bg-cleenheartsSecondary"
            }
            style={{ width: `${scrollPercentage}%` }}
          />
        </span>
      </div>
    </div>
  );
};

export default CopyRightFooter;
