import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope } from "@fortawesome/free-solid-svg-icons";

const SocialContact = () => {
  return (
    <div
      className={
        "md:flex hidden items-center justify-between bg-cleenheartsBase py-[4px] min-[1630px]:px-24 px-3"
      }
    >
      <ul className={"flex items-center"}>
        <li className={"flex items-center"}>
          <FontAwesomeIcon
            icon={faEnvelope}
            size={"lg"}
            style={{ color: "#f6d469" }}
            className={"mr-2"}
          />

          <div className={"relative"}>
            <a
              href={"mailto:contact@akarma.in"}
              className={
                "hover-underline-animation text-GlossyGrape hover:text-cleenheartsSecondary"
              }
            >
              contact@akarma.in
            </a>
          </div>
        </li>
      </ul>
    </div>
  );
};

export default SocialContact;
