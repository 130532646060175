import {
  RESENT_EMAIL_REQUEST,
  RESENT_EMAIL_SUCCESS,
  RESENT_EMAIL_FAILURE,
} from "../../action/types";

const initialState = {
  data: [],
  loading: false,
  error: null,
};

const ResendEmailReducer = (state = initialState, action) => {
  switch (action.type) {
    case RESENT_EMAIL_REQUEST:
      return { ...state, loading: true, error: null };
    case RESENT_EMAIL_SUCCESS:
      return { ...state, data: action.payload, loading: false, error: null };
    case RESENT_EMAIL_FAILURE:
      return { ...state, loading: false, error: action.payload };
    default:
      return state;
  }
};

export default ResendEmailReducer;
