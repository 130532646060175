import {
  GROUP_LIST_REQUEST,
  GROUP_LIST_SUCCESS,
  GROUP_LIST_FAILURE,
} from "../../action/types";

const initialState = {
  data: [],
  loading: false,
  error: null,
};

const GroupListReducer = (state = initialState, action) => {
  switch (action.type) {
    case GROUP_LIST_REQUEST:
      return { ...state, loading: true, error: null };
    case GROUP_LIST_SUCCESS:
      return { ...state, data: action.payload, loading: false, error: null };
    case GROUP_LIST_FAILURE:
      return { ...state, loading: false, error: action.payload };
    default:
      return state;
  }
};

export default GroupListReducer;
