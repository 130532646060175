import {
  RESET_STATE,
  SMART_SEARCH_LOCATION_REQUEST,
  SMART_SEARCH_LOCATION_SUCCESS,
  SMART_SEARCH_LOCATION_FAILURE,
} from "../../action/types";

const initialState = {
  data: [],
  loading: false,
  error: null,
};

const SmartSearchApiReducer = (state = initialState, action) => {
  switch (action.type) {
    case SMART_SEARCH_LOCATION_REQUEST:
      return { ...state, loading: true, error: null };
    case SMART_SEARCH_LOCATION_SUCCESS:
      return { ...state, data: action.payload, loading: false, error: null };
    case RESET_STATE:
      return initialState;
    case SMART_SEARCH_LOCATION_FAILURE:
      return { ...state, loading: false, error: action.payload };
    default:
      return state;
  }
};

export default SmartSearchApiReducer;
