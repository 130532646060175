import { combineReducers } from "redux";
// Auth
import authReducer from "./auth/AuthReducer";
import forgotReducer from "./auth/ForgotPasswordReducer";
import resetReducer from "./auth/ResetPasswordReducer";
import signupReducer from "./auth/SignupReducer";
// COntact us
import contactReducer from "./contactus/ContactUsReducer";
// common
import stateCityReducer from "./common/StateCityReducer";
import lookupReducer from "./common/LookupReducer";
import countryReducer from "./common/CountryReducer";
// profile
import pincodeReducer from "./profile/PincodeReducer";
import getUserProfileReducer from "./profile/GetUserProfileReducer";
import updateUserProfileReducer from "./profile/UpdateUserProfileReducer";
// details
import tabMasterReducer from "./tabs/TabMasterReducer";
import summaryTabReducer from "./tabs/SummaryTabReducer";
import demographicsTabReducer from "./tabs/DemographicsTabReducer";
import areaofWorkTabReducer from "./tabs/AreaofWorkTabReducer";
import trusteesMembersDirectorsTabRaducer from "./tabs/TrusteesMembersDirectorsTabRaducer";
import governmentSupportTabReducer from "./tabs/GovernmentSupportTabReducer";
import companyProfileTabReducer from "./tabs/CompanyProfileTabReducer";
import directorsTabRaducer from "./tabs/DirectorsTabRaducer";
import csrInfoTabReducer from "./tabs/CsrInfoTabReducer";
import individualProfileTabReducer from "./tabs/IndividualProfileTabReducer";
import connectionTabReducer from "./tabs/ConnectionTabReducer";
// searchbar
import searchApiReducer from "./smartseach/searchApiReducer";
// smart search-community
import dashboardReducer from "./smartseach/DashboardReducer";
import filterResponseData from "./smartseach/FilterResponseDataReducer";
import filters from "./smartseach/FiltersReducer";
import SmartSearchApiReducer from "./smartseach/smartSearchReducer";
// favourite
import favoriteReducer from "./favorite/FavoriteReducer";
import favoriteData from "./favorite/FavoriteDataReducer";
import createListReducer from "./favorite/CreateListReducer";
import changeListReducer from "./favorite/ChangeListReducer";
import groupListReducer from "./favorite/GroupListReducer";
import editGroupNameReducer from "./favorite/EditGroupNameReducer";
import deleteGroupNameReducer from "./favorite/DeleteGroupNameReducer";
// verification
import emailVerifyReducer from "./verification/EmailVerifyReducer";
import resendEmailReducer from "./verification/ResendEmailReducer";
import sendMobileOtpReducer from "./verification/SendMobileOtpReducer";
import verifyMobileOtpReducer from "./verification/VerifyMobileOtpReducer";

const rootReducer = combineReducers({
  // Auth
  auth: authReducer,
  forgotReducer: forgotReducer,
  resetReducer: resetReducer,
  signupReducer: signupReducer,
  // Contact us
  contactReducer: contactReducer,
  // common
  stateCityReducer: stateCityReducer,
  lookupReducer: lookupReducer,
  countryReducer: countryReducer,
  // profile
  pincodeReducer: pincodeReducer,
  getUserProfileReducer: getUserProfileReducer,
  updateUserProfileReducer: updateUserProfileReducer,
  // details
  tabMasterReducer: tabMasterReducer,
  summaryTabReducer: summaryTabReducer,
  demographicsTabReducer: demographicsTabReducer,
  areaofWorkTabReducer: areaofWorkTabReducer,
  trusteesMembersDirectorsTabRaducer: trusteesMembersDirectorsTabRaducer,
  governmentSupportTabReducer: governmentSupportTabReducer,
  companyProfileTabReducer: companyProfileTabReducer,
  directorsTabRaducer: directorsTabRaducer,
  csrInfoTabReducer: csrInfoTabReducer,
  individualProfileTabReducer: individualProfileTabReducer,
  connectionTabReducer: connectionTabReducer,
  // searchbar
  searchApiReducer: searchApiReducer,
  // smart search-community
  dashboardReducer: dashboardReducer,
  filterResponseData: filterResponseData,
  filters: filters,
  SmartSearchApiReducer: SmartSearchApiReducer,
  // favorite
  favoriteReducer: favoriteReducer,
  favoriteData: favoriteData,
  createListReducer: createListReducer,
  changeListReducer: changeListReducer,
  groupListReducer: groupListReducer,
  editGroupNameReducer: editGroupNameReducer,
  deleteGroupNameReducer: deleteGroupNameReducer,
  // verification
  emailVerifyReducer: emailVerifyReducer,
  resendEmailReducer: resendEmailReducer,
  sendMobileOtpReducer: sendMobileOtpReducer,
  verifyMobileOtpReducer: verifyMobileOtpReducer,
});

export default rootReducer;
