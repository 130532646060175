import React, { Fragment } from "react";
import { Dialog, Transition } from "@headlessui/react";
import FilterSearchBar from "./FilterSearchBar";

const SearchBarMobile = ({ searchBar, setSearchBar }) => {
  return (
    <Transition.Root show={searchBar} as={Fragment}>
      <Dialog as={"div"} className={"z-20"} onClose={() => setSearchBar(false)}>
        <div
          className={"fixed top-[62px] inset-0 z-20 w-screen overflow-y-auto"}
        >
          <div
            className={"flex items-end justify-center sm:items-center sm:p-0"}
          >
            <Transition.Child
              as={Fragment}
              enter={"ease-out duration-300"}
              enterFrom={"opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"}
              enterTo={"opacity-100 translate-y-0 sm:scale-100"}
              leave={"ease-in duration-200"}
              leaveFrom={"opacity-100 translate-y-0 sm:scale-100"}
              leaveTo={"opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"}
            >
              <Dialog.Panel
                className={
                  "relative transform bg-white shadow-xl transition-all w-full !px-4 py-2"
                }
              >
                <FilterSearchBar />
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

export default SearchBarMobile;
