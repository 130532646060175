import {
  PINCODE_REQUEST,
  PINCODE_SUCCESS,
  PINCODE_FAILURE,
} from "../../action/types";

const initialState = {
  data: [],
  loading: false,
  error: null,
};

const PincodeReducer = (state = initialState, action) => {
  switch (action.type) {
    case PINCODE_REQUEST:
      return { ...state, loading: true, error: null };
    case PINCODE_SUCCESS:
      return { ...state, data: action.payload, loading: false, error: null };
    case PINCODE_FAILURE:
      return { ...state, loading: false, error: action.payload };
    default:
      return state;
  }
};

export default PincodeReducer;
